var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hasWhiteLabel
    ? _c("div", { staticClass: "powered-by flex items-center" }, [
        _c("div", { staticClass: "ml-2 flex items-center justify-center" }, [
          _c("img", {
            attrs: {
              src: _vm.logo,
              width: _vm.width,
              height: _vm.height,
              alt: "logo",
            },
          }),
        ]),
        _c("div", { staticClass: "ml-2 powered-by-text" }, [
          _vm._v("powered by"),
        ]),
        _c("div", { staticClass: "ml-1 powered-by-company" }, [
          _vm._v("Let’s Connect"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }