<template>
  <div class="powered-by flex items-center" v-if="!hasWhiteLabel">
    <div class="ml-2 flex items-center justify-center">
      <img :src="logo" :width="width" :height="height" alt="logo" />
    </div>
    <div class="ml-2 powered-by-text">powered by</div>
    <div class="ml-1 powered-by-company">Let’s Connect</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PoweredBy',
  props: {
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '20'
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo/logo.svg')
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel'
    })
  }
}
</script>

<style lang="scss" scoped>
.powered-by {
  background: #e6ecf1;
  height: 26px;
  width: 100%;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  &-text {
    color: rgba(0, 0, 0, 0.5);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
  &-company {
    color: #3034f7;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
